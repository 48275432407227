import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Astrict from "./util/astrict";
//import { globalRegister } from "./global";
import * as ELIcons from "@element-plus/icons-vue"; //全局注册elementplus icon图标
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
import "element-plus/dist/index.css";
// import BaiduMap from "vue-baidu-map-3x";
const app = createApp(App);

const icons: any = ELIcons;
for (const iconName in icons) {
  app.component(iconName, icons[iconName]);
}
// app.use(BaiduMap, {
//   ak: "Bc1CYRoPBgwMrcOhkOsu2aQiK1Bhsun3",
//   // v:'2.0',  // 默认使用3.0
//   // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
// });
// app.use(globalRegister);

app.use(store).use(Astrict).use(router).mount("#app");
